import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Curso from "../components/curso";
import Tree from "../images/cursos/Tree.webp";
import Mandala from "../images/cursos/Mandala.webp";

const Estados = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted ? (
    <Layout>
      <Curso
        title="Estados mentales y factores mentales "
        phrase="Comprender nuestra mente para poderla transformar."
        time="10 Horas"
        class="8 Sesiones"
        students="3 - 6 Estudiantes"
        firstImg={Tree}
        secondImg={Mandala}
        description="En este curso profundizaremos en la comprensión de las estructuras mentales y los diferentes estados de consciencia planteados en el budismo. También veremos los fundamentos de la práctica de la meditación y revisaremos, a través de la teoría y la práctica, diferentes técnicas básicas."
        list={[
          "Revisión de ideas del curso de Fundamentos de la filosofía budista que serán fundamentales para este curso.",
          "Estados de conciencia con raíces no saludables.",
          "Estados de conciencia con raíces saludables.",
        ]}
        duracion="8 sesiones de entre 60 y 75 min."
        cupo="mínimo 3 personas, máximo 6 personas."
        precio=" $2,000 MXN / $120 USD aprox."
        top="10%"
        imgWidth="16%"
        right="5%"
        topMob="20%"
        rightMob="1%"
        imgWidthMob="26%"
      />
    </Layout>
  ) : null;
};

export default Estados;
